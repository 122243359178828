import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './NotFound.css';

type PT = {
  title?: string;
};

const NotFound: FC<PT> = ({ title }) => {
  useStyles(s);
  return (
    <div className={s.root}>
      <div className={s.container}>
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default NotFound;
